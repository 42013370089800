import { Col, Row, Table, Button, Form, Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";

class index extends Component {
  state = {
    pauseUserModal: false,
    addPartnerModal: false,
    editPartnerModal: false,
    userAction: null,
    selectedPartnerId: null,
    selectedPartnerData: null,
    selectedFile: null,
    selectedImageSrc: null,
  };

  componentDidMount() {
    this.props.loadPartnerOnboardingCodes();
  }

  // Shows the pause/unpause pop up for the particular partner
  handlePauseModal = (action, partnerId) => {
    const { pauseUserModal } = this.state;
    if (partnerId) {
      this.setState({
        pauseUserModal: !pauseUserModal,
        userAction: action,
        selectedPartnerId: partnerId,
      });
    } else {
      this.setState({
        pauseUserModal: !pauseUserModal,
        userAction: null,
        selectedPartnerId: null,
      });
    }
  };

  // Update partner info
  submitUserAction = (action) => {
    const { selectedPartnerId } = this.state;

    this.props
      .updatePartnerActionAdmin({ selectedPartnerId, action: action })
      .then((res) => {
        if (res && res.data && res.data.status) {
          this.setState({
            pauseUserModal: false,
            selectedPartnerId: null,
            userAction: null,
          });
          notifySuccess(res?.data?.message || "Done");
          this.props.loadPartnerOnboardingCodes();
          // if (action === "delete") this.props.history.goBack();
        } else {
          var errorMessage =
            res?.data?.message || res?.err?.message || "Something went wrong";
          notifyError(errorMessage);
        }
      });
  };

  handleAddModal = () => {
    const { addPartnerModal } = this.state;
    this.setState({
      addPartnerModal: !addPartnerModal,
      selectedPartnerData: null,
    });
  };

  // Open the edit pop up for partner info
  handleEditModal = (id, data) => {
    const { editPartnerModal } = this.state;

    this.setState({
      editPartnerModal: !editPartnerModal,
      selectedPartnerId: id,
      selectedPartnerData: data,
    });
  };

  handleFile = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({
        selectedImageSrc: [reader.result],
      });
    }.bind(this);
    this.setState({ selectedFile: file });
  };

  handleCloseModal = () => {
    this.setState({
      addPartnerModal: false,
      editPartnerModal: false,
      selectedPartnerData: null,
      selectedPartnerId: null,
      selectedFile: null,
      selectedImageSrc: null,
    });
  };

  // handleRedictSubcode = (data) => {

  // }

  // Add, Edit and Delete Icons
  submitForm = (values) => {
    const { selectedFile, addPartnerModal, selectedPartnerId } = this.state;

    var formData = new FormData();

    formData.append("action", addPartnerModal ? "Add" : "Edit");
    formData.append("data", JSON.stringify(values));
    if (selectedPartnerId) formData.append("id", selectedPartnerId);

    if (selectedFile) {
      if (!selectedFile.type.includes("image/")) {
        notifyError("Please upload image file");
        return false;
      }
      formData.append("file", selectedFile);
    }

    this.props.addEditPartnerDataAdmin(formData).then((res) => {
      if (res && res.data && res.data.status) {
        notifySuccess(res?.data?.message || "Done");
        this.setState({
          addPartnerModal: false,
          editPartnerModal: false,
          selectedFile: null,
          selectedImageSrc: null,
          selectedPartnerId: null,
          selectedPartnerData: null,
        });
        this.props.loadPartnerOnboardingCodes();
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "#",
      dataIndex: "app_council_information_id",
      key: "app_council_information_id",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "1rem", width: "40px" }}>
          {record.sr_no || ""}
        </p>
      ),
    },
    {
      title: "Partner name",
      dataIndex: "partner_name",
      key: "partner_name",
      render: (_text, record) => (
        <p
          // className="brand-name"
          style={{ cursor: "pointer", width: "250px" }}
          onClick={() =>
            this.handleEditModal(record.app_partner_onboarding_codes_id, record)
          }
        >
          {record.partner_name || ""}
        </p>
      ),
    },
    {
      title: "Icon",
      dataIndex: "partner_icon_url",
      key: "partner_icon_url",
      render: (_text, record) => (
        <div style={{ width: "100px", marginLeft: "-10px" }}>
          <img
            src={
              record?.partner_icon_url ||
              `https://via.placeholder.com/60x60?text=`
            }
            alt=""
            style={{ width: "44px", borderRadius: "70%", maxWidth: "50px" }}
          />
        </div>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (_text, record) => (
        <p className="brand-name" style={{ width: "180px" }}>
          {record.code || ""}
        </p>
      ),
    },

    {
      title: "No. subcodes",
      dataIndex: "total_records",
      key: "total_records",
      render: (_text, record) => (
        <p
          className="brand-name"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            width: "180px",
            marginLeft: "8px",
          }}
          onClick={() => {
            const { history } = this.props;

            history.push(
              `/admin/partner-codes/subcodes/${record.app_partner_onboarding_codes_id}/${record.partner_name}`
            );
          }}
        >
          {record.total_records || "0"}
        </p>
      ),
    },

    {
      title: "No. subcodes used",
      dataIndex: "used_records",
      key: "used_records",
      render: (_text, record) => (
        <p
          className="brand-name"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            width: "180px",
          }}
          onClick={() => {
            const { history } = this.props;
            history.push(
              `/admin/partner-codes/subcode-used/${record.app_partner_onboarding_codes_id}/${record.partner_name}`
            );
          }}
        >
          {record.used_records || "0"}
        </p>
      ),
    },

    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        var action = record.is_paused === 0 ? "Pause" : "Unpause";
        return (
          <div className="btn-actions" style={{ width: "100px" }}>
            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) =>
                this.handlePauseModal(
                  action,
                  record.app_partner_onboarding_codes_id
                )
              }
            >
              {action}
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { partnerOnboardingCodes } = this.props;
    const {
      pauseUserModal,
      userAction,
      addPartnerModal,
      selectedPartnerData,
      editPartnerModal,
      selectedImageSrc,
    } = this.state;
    let results = [];
    if (partnerOnboardingCodes && partnerOnboardingCodes.length > 0) {
      for (var i = 0; i < partnerOnboardingCodes.length; i++) {
        var key = `partner-code-${partnerOnboardingCodes[i].app_partner_onboarding_codes_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...partnerOnboardingCodes[i],
        });
      }
    }

    return (
      <>
        <BasicLayout>
          <Row className="section-title">
            <Col md={24}>
              <h4>Partner onboarding codes</h4>
            </Col>
            {/* <Col md={23}>
            <div>
              <Button
                className="btn-add-card"
                type="primary"
                onClick={this.handleAddModal}
              >
                Add new partner
              </Button>
            </div>
          </Col> */}
          </Row>

          <Row
            className="card-table"
            style={{
              minHeight: "70vh",
            }}
          >
            <Table
              sortOrder={false}
              dataSource={results}
              columns={this.columns}
            />
          </Row>

          {(addPartnerModal || (editPartnerModal && selectedPartnerData)) && (
            <CustomModal
              className={"signup-setting-modal"}
              visible={addPartnerModal || editPartnerModal}
              onClose={this.handleCloseModal}
            >
              <Form
                size="large"
                initialValues={selectedPartnerData}
                onFinish={(val) => this.submitForm(val)}
                layout="vertical"
                className="form-login"
              >
                <p className="txt-title">Add a new partner code</p>
                <div className="custom-form-container">
                  <Form.Item
                    name="partner_name"
                    label="Partner Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter partner name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Partner name"
                      type="text"
                      value={selectedPartnerData?.partner_name || ""}
                    />
                  </Form.Item>

                  <Form.Item
                    name="code"
                    label="Code"
                    rules={[
                      {
                        required: true,
                        message: "Please enter code",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Code"
                      type="text"
                      value={selectedPartnerData?.code || ""}
                    />
                  </Form.Item>

                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label">
                      <label htmlFor="Partner icon" title="Partner icon">
                        Upload icon(1024 X 1024)
                      </label>
                      <div className="custom-new-partner-input">
                        <img
                          src={
                            selectedImageSrc ||
                            selectedPartnerData?.partner_icon_url ||
                            `https://via.placeholder.com/60x60?text=`
                          }
                          alt="Partner icon"
                          style={{
                            borderRadius: "10rem",
                          }}
                        />
                        <input type="file" onChange={this.handleFile} />
                        <span>Edit</span>
                      </div>
                    </div>
                  </div>
                </div>

                <Button
                  className="text-gray"
                  htmlType="submit"
                  type="primary"
                  block
                >
                  Save
                </Button>
              </Form>
            </CustomModal>
          )}

          <CustomModal
            className={"signup-setting-modal"}
            visible={pauseUserModal}
            onClose={this.handlePauseModal}
          >
            <p className="txt-title">{userAction} this parnter</p>
            {userAction === "Pause" ? (
              <p className="txt-info">
                Users will not be able to use codes and sub codes for this
                partner.
              </p>
            ) : (
              <p className="txt-info">
                Users will be able to use codes and sub codes for this partner.
              </p>
            )}

            <Button
              type="primary"
              block
              onClick={() => this.submitUserAction(userAction)}
            >
              {userAction}
            </Button>
          </CustomModal>
        </BasicLayout>

        <div style={{ padding: "1.3rem" }}>
          <Button
            className="btn-add-partner"
            type="primary"
            onClick={this.handleAddModal}
          >
            Add new partner
          </Button>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    partnerOnboardingCodes: state.partnerOnboardingCodes,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
