import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import "./index.less";

// Admin login page
const Index = (props) => {
  const [form] = Form.useForm();
  const [loginDetails, setLoginDetails] = useState({
    email_id: "",
    password: "",
  });
  const [isLoading, setLoading] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [secureEntry, setSecureEntry] = useState(true);
  const [isForgotSuccess, setForgotSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [forgotEmailAddress, setForgotEmailAddress] = useState("");

  useEffect(() => {
    var storedLoginInfo = localStorage.getItem("admin_login_info");
    if (storedLoginInfo) {
      var loginInfo = JSON.parse(storedLoginInfo);
      setLoginDetails({
        email_id: loginInfo.email_id,
        password: loginInfo.password,
      });
      setRememberMe(loginInfo?.remember || false);
    }
    setLoading(false);
  }, []);

  // Validate the admin after provide the auth info and if success redirect admin to the dashboard
  // If failes then it will shows proper error message
  const onFinish = async (values) => {
    setErrorMessage(null);
    try {
      const params = {
        email_id: values.email_id,
        password: values.password,
      };
      var result = await props.loginAdmin(params);

      const { data, err } = result;
      if (data && data.status) {
        props.setAdminInfo(data.data.info);
        if (rememberMe) {
          localStorage.setItem(
            "admin_login_info",
            JSON.stringify({ ...params, ...{ remember: rememberMe } })
          );
        } else {
          localStorage.removeItem("admin_login_info");
        }

        props.history.replace("/admin/dashboard");
      } else {
        setErrorMessage(data?.message || err?.message || "Server error!");
      }
    } catch (error) {
      setErrorMessage({ password: error?.message || "Server error!" });
    }
  };

  // It will share the new password on the related admin email inbox and redirect user to the forgot password screen.
  const onForgotPassword = async (emailAddress = null) => {
    setErrorMessage(null);

    const emailId = emailAddress
      ? emailAddress
      : form.getFieldValue("email_id");

    if (!emailId) {
      setErrorMessage({ email: "Please enter your email address" });
      return;
    }

    const { errors } = form.getFieldsError(["email_id"])[0];
    if (errors && errors.length > 0) {
      setErrorMessage({ email: errors[0] });
      return;
    }

    try {
      const params = {
        email_id: emailId,
      };
      var result = await props.forgotPassword(params);
      const { data, err } = result;
      if (data && data.status) {
        setForgotEmailAddress(emailId);
        setForgotSuccess(true);
      } else {
        setErrorMessage({ email: err?.message || "Server error!" });
      }
    } catch (error) {
      setErrorMessage({ email: error?.message || "Server error!" });
    }
  };

  // It's store the remember me flag for the next time login.
  const handleRememberMe = (e) => {
    setRememberMe(!rememberMe);
  };

  return (
    <div className="auth-container">
      <Row style={{ height: "100%" }}>
        {/* <Col md={12} xs={24} className="page-background">
          <img src="/images/logo.png" alt="SuperFi logo" className="logo-img" />
        </Col> */}
        <div
          className="test-class"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col md={12} xs={24} className="auth-login">
            <Card className="auth-card">
              <Row className="form-container">
                <Col span={24}>
                  {isForgotSuccess ? (
                    <>
                      <h3 className="txt-forgot-success">
                        We’ve sent you an email to {forgotEmailAddress} with a
                        temporary password
                      </h3>

                      <Button
                        type="primary"
                        block
                        onClick={() => onForgotPassword(forgotEmailAddress)}
                      >
                        Resend email
                      </Button>
                      <Button block onClick={() => setForgotSuccess(false)}>
                        Back to login
                      </Button>
                    </>
                  ) : (
                    <>
                      <h2>Log in to SuperFi Admin panel</h2>
                      <p>Admin dashboard</p>
                      <hr />
                      {!isLoading && (
                        <Form
                          form={form}
                          initialValues={loginDetails}
                          size="large"
                          layout="vertical"
                          onFinish={onFinish}
                          className="form-login"
                        >
                          <div className="custom-form-container">
                            <Form.Item
                              name="email_id"
                              label="Email Address"
                              className="login-label"
                              rules={[
                                {
                                  type: "email",
                                  message: "Please enter your email address",
                                },
                                {
                                  required: true,
                                  message: "Please enter your email address",
                                },
                              ]}
                            >
                              <Input
                                className="login-input"
                                placeholder="Your Email Address"
                                type="email"
                              />
                            </Form.Item>

                            {errorMessage && errorMessage.email && (
                              <div className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">
                                  {errorMessage?.email || ""}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="custom-form-container">
                            <Form.Item
                              name="password"
                              label="Enter Password"
                              className="login-label"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Password incorrect. Please try again",
                                },
                              ]}
                            >
                              <Input
                                className="login-input"
                                placeholder="Your Password"
                                type={secureEntry ? "password" : "text"}
                              />
                            </Form.Item>

                            <span
                              className="password-helper"
                              onClick={() => setSecureEntry(!secureEntry)}
                            >
                              {secureEntry ? "Show" : "Hide"}
                            </span>

                            {errorMessage && errorMessage.password && (
                              <div className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">
                                  {errorMessage?.password || ""}
                                </div>
                              </div>
                            )}
                          </div>
                          <label className="custom-check-box">
                            <span className="text">Remember me</span>
                            <input
                              type="checkbox"
                              onChange={() => handleRememberMe()}
                              checked={rememberMe}
                            />
                            <span className="checkmark"></span>
                          </label>

                          <Button htmlType="submit" type="primary" block>
                            Log in
                          </Button>
                        </Form>
                      )}
                      <div className="text-center">
                        <Button
                          ghost
                          className="btn-forgot"
                          onClick={() => onForgotPassword()}
                        >
                          Forgotten password? <br />
                          <span className="custom-link">
                            Receive a temporary one
                          </span>
                        </Button>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Card>
            <p className="copyright-text">
              Copyright © 2024 SuperFi Finance Ltd. All Rights Reserved
            </p>
          </Col>
        </div>
      </Row>
    </div>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Index));
