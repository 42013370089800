// api status
export const make3 = (name) => [
  name + "_REQUEST",
  name + "_SUCCESS",
  name + "_FAIL",
];
// api status
export const loadmake3 = (name) => [
  name + "_START",
  name + "_COMPLETE",
  name + "_FAIL",
];
// actions for api calling
const promiseTypes = [
  "CHECK_AUTH_TOKEN",
  "GET_USERS",
  "VIEW_USER_INFO",
  "VIEW_USER_INFO_LIVE",
  "GET_CARD_CATALOG",
  "UPDATE_USER_ACTION",
  "VIEW_CARD_CATALOG",
  "UPDATE_CARD_CATALOG",
  "̈UPDATE_CASHBACK_AMOUNT",
  "GET_DASHBOARD_STATS",
  "GET_COMPLETETASK_LIST",
  "SEND_REWARD_DATA",
  "GET_REWARD_LIST",
  "UPDATE_NEW_SIGNUP_SETTING",
  "GET_ADMIN_USERS",
  "UPDATE_ADMIN",
  "USER_CURRENT_PAGE",
  "GET_MERCHANT_ICONS",
  "UPDATE_MERCHANT_ICONS",
  "GET_MARKETPLACE_CLICK_INFO",
  "GET_TAGS",
  "GET_MERCHANT_LISTING",
  "GET_COUNCIL_TAX",
  "GET_GENERAL_TAB_REPORTS",
  "GET_FINANCIAL_TAB_REPORTS",
  "GET_PARTNER_ONBOARDING_CODE",
  "̈UPDATE_ADMIN_FIELDS",
  "GET_CONSUMER_DUTY_TAB_REPORTS",
  "GET_ADMIN_REPORT",
  "GET_SPIN_THE_WHEEL",
  "GET_USER_VOUCHER",
  "UPDATE_VOUCHER_INFO",
  "UPDATE_SPIN_WHEEL_INFO",
  "UPDATE_PARTNER_ACTION",
  "ADD_EDIT_PARTNER_ACTION",
  "GET_SUB_CODE_LIST",
  "DELETE_SUB_CODE",
  "ADD_SUB_CODE",
  "GET_USED_SUB_CODE_LIST",
  "DELETE_USED_SUB_CODE_LIST",
  "PAUSE_USED_SUB_CODE_LIST",
  "GET_CASHBACK_RETAILERS_LIST",
  "DELETE_RETIALER",
  "ADD_UPDATE_RETAILER"
].reduce((prev, cur) => {
  make3(cur).forEach((key) => {
    prev[key] = key;
  });

  return prev;
}, {});

// actions for api calling without loader
const loadPromiseTypes = ["LOAD_MORE_ITEMS"].reduce((prev, cur) => {
  loadmake3(cur).forEach((key) => {
    prev[key] = key;
  });

  return prev;
}, {});

// actions for local data set
const simpleTypes = [
  "SET_ROUTE",
  "SET_ADMININFO",
  "CLEAR_DATA",
  "LOGOUT",
].reduce((prev, cur) => {
  prev[cur] = cur;
  return prev;
}, {});

export const types = { ...simpleTypes, ...promiseTypes, ...loadPromiseTypes };
