/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Card, Row, Form, Input, Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import {
  generalTableData,
  // locationsBreakdownTableData,
  // customerBreakdownTableData,
  financialTableData,
  collectionsReportingTableData,
  consumerDutyTableData,
  // paymentTableData,
} from "../../common/utils";
import CustomModal from "../../components/CustomModal";
import { notifyError } from "../../common/notification";
import moment from "moment";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGeneral: true,
      isFinancial: false,
      isCollections: false,
      isConsumerDuty: false,
      fieldUpdateModalNps: false,
      fieldUpdateModalConsumer: false,
      versionUpdateModal: false,
      npsScore: "",
      npsRating: "",
      consumerTabMetric: "",
      partnerDropdownOption: "All users",
      partnerDropdownData: {
        app_partner_onboarding_codes_id: 1,
        code: "280592",
        is_deleted: 0,
        is_paused: 0,
        partner_name: "All users",
      },
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    this.reportPageDataProps(null);
  }

  reportPageDataProps(partnerId) {
    this.props.loadAdminDashboardStats(partnerId);
    this.props.getMarketplaceClickInfo(partnerId);
    this.props.loadGeneralTabReports(partnerId);
    this.props.loadFinancialTabReports(partnerId);
    this.props.getConsumerDutyTabReports(partnerId);
    this.props.loadPartnerOnboardingCodes();
  }

  handleCloseModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      fieldUpdateModalNps: false,
      fieldUpdateModalConsumer: false,
      versionUpdateModal: false,
    });
  };

  handleNpsFieldModal = () => {
    const { fieldUpdateModalNps } = this.state;
    this.setState({
      fieldUpdateModalNps: !fieldUpdateModalNps,
    });
  };

  handleConsumerDutyModal = (data) => {
    const { fieldUpdateModalConsumer } = this.state;
    this.setState(
      {
        consumerTabMetric: data,
      },
      () => {
        this.setState({
          fieldUpdateModalConsumer: !fieldUpdateModalConsumer,
        });
      }
    );
  };

  submitForm = (values) => {
    if (isNaN(values.score)) {
      notifyError("Amount must be a number");
      return;
    }
    if (!isNaN(values.rating)) {
      notifyError("Specify a rating");
      return;
    }

    values.type = this.state.fieldUpdateModalNps
      ? "NPS"
      : this.state.consumerTabMetric.identifier;
    values._partner_id =
      this?.state?.partnerDropdownData?.app_partner_onboarding_codes_id || 1;

    this.setState({
      fieldUpdateModalConsumer: false,
      fieldUpdateModalNps: false,
      npsScore: "",
      npsRating: "",
      consumerTabMetric: "",
    });
    // console.log("values ==>", values);
    this.props.saveAdminEditableFields(values);
    this.props.getConsumerDutyTabReports(
      this?.state?.partnerDropdownData?.app_partner_onboarding_codes_id || 1
    );
    this.formRef.current.resetFields();
  };

  handleButtonClick = (type) => {
    if (type === "GENERAL") {
      this.setState({
        isGeneral: true,
        isFinancial: false,
        isCollections: false,
        isConsumerDuty: false,
      });
    } else if (type === "FINANCIAL") {
      this.setState({
        isGeneral: false,
        isFinancial: true,
        isCollections: false,
        isConsumerDuty: false,
      });
    } else if (type === "COLLECTIONS") {
      this.setState({
        isGeneral: false,
        isFinancial: false,
        isCollections: true,
        isConsumerDuty: false,
      });
    } else if (type === "CONSUMER") {
      this.setState({
        isGeneral: false,
        isFinancial: false,
        isCollections: false,
        isConsumerDuty: true,
      });
    }
  };

  exportReport = async (
    totalBreakdownData,
    totalLocationData,
    totalMarketplaceClicks,
    userLocationData,
    userBreakdownData,
    userClickData,
    consumerDutyTableData,
    versionNumber,
    cantAffordToPayCount,
    speakToYourProviderCount,
    stepChangeCount,
    lostMyJobCount,
    housingSupportTotalCount,
    sortedOverdueArray,
    sortedAtRiskArray,
    sortedCantAffordArray
  ) => {
    // console.log("exportReport");
    this.setState({
      versionUpdateModal: false,
    });
    let someResponse = await this.props.exportAdminReport({
      dashboard: this.props.dashboard,
      marketplaceClickInfo: this.props.marketplaceClickInfo,
      generalTabReports: this.props.generalTabReports,
      financialTabReports: this.props.financialTabReports,
      consumerDutyTabReports: this.props.consumerDutyTabReports,
      consumerDutyTabClicks: this.props.consumerDutyTabClicks,
      collectionTabReports: this.props.collectionTabReports,
      lastMonthAdminData: this.props.lastMonthAdminData,
      partner_name: this.state.partnerDropdownOption,
      partner_data: this.state.partnerDropdownData,
      totalBreakdownData,
      totalLocationData,
      totalMarketplaceClicks,
      userLocationData,
      userBreakdownData,
      userClickData,
      consumerDutyTableData,
      version: versionNumber?.version || "1",
      cantAffordToPayCount,
      speakToYourProviderCount,
      stepChangeCount,
      lostMyJobCount,
      housingSupportTotalCount,
      sortedOverdueArray,
      sortedAtRiskArray,
      sortedCantAffordArray,
    });
    if (someResponse?.data?.link) {
      window.open(someResponse?.data?.link, "_blank");
    }
  };

  handleDropdownItemClick = (e) => {
    let id = e.split("-").pop();
    let indexLabel = this.props.partnerOnboardingCodes.findIndex(
      (data) => id == data.app_partner_onboarding_codes_id
    );

    this.setState({
      partnerDropdownOption:
        indexLabel >= 0
          ? this.props.partnerOnboardingCodes[indexLabel].partner_name
          : "",
      partnerDropdownData:
        indexLabel >= 0 ? this.props.partnerOnboardingCodes[indexLabel] : {},
    });
    // also need to set data to change table data based on partner.
    this.reportPageDataProps(
      this.props.partnerOnboardingCodes[indexLabel]
        .app_partner_onboarding_codes_id
    );
  };

  render() {
    const {
      isGeneral,
      isFinancial,
      isCollections,
      isConsumerDuty,
      fieldUpdateModalNps,
      fieldUpdateModalConsumer,
      consumerTabMetric,
      partnerDropdownOption,
      versionUpdateModal,
    } = this.state;

    const {
      dashboard,
      marketplaceClickInfo,
      generalTabReports,
      financialTabReports,
      consumerDutyTabReports,
      consumerDutyTabClicks,
      partnerOnboardingCodes,
      collectionTabReports,
      lastMonthAdminData,
      consumerDutyTabClicksLastMonth,
      secondLastMonthAdminData,
      thirdLastMonthAdminData,
    } = this.props;

    let currentMonth = moment().format("MMM YYYY");
    let lastMonth = moment().subtract(1, "month").format("MMM YYYY");
    let secondLastMonth = moment().subtract(2, "month").format("MMM YYYY");
    let thirdLastMonth = moment().subtract(3, "month").format("MMM YYYY");

    let missedPaymentsArray = [];
    let atRiskPaymentsArray = [];
    let cantAffordArray = [];

    let thisMonthOverDueData = financialTabReports?.thisMonthOverdueArray || [];
    let lastMonthOverDueData = financialTabReports?.lastMonthOverdueArray || [];

    let thisMonthAtRiskData = financialTabReports?.thisMonthAtRiskArray || [];
    let lastMonthAtRiskData = financialTabReports?.lastMonthAtRiskArray || [];

    for (let passData of thisMonthOverDueData) {
      let findIndex = missedPaymentsArray.findIndex(
        (data) => data.name == passData.name
      );
      if (findIndex >= 0) {
        missedPaymentsArray[findIndex].current = Number(passData.total);
      } else {
        missedPaymentsArray.push({
          name: passData.name,
          current: Number(passData.total),
          last: 0,
        });
      }
    }
    for (let passData of lastMonthOverDueData) {
      let findIndex = missedPaymentsArray.findIndex(
        (data) => data.name == passData.name
      );
      if (findIndex >= 0) {
        missedPaymentsArray[findIndex].last = Number(passData.total);
      } else {
        missedPaymentsArray.push({
          name: passData.name,
          last: Number(passData.total),
          current: 0,
        });
      }
    }
    let sortedOverdueArray = missedPaymentsArray.sort(function (o1, o2) {
      return o1.name > o2.name ? 1 : o1.name < o2.name ? -1 : 0;
    });

    //at risk data
    for (let passData of thisMonthAtRiskData) {
      let findIndex = atRiskPaymentsArray.findIndex(
        (data) => data.name == passData.name
      );
      if (findIndex >= 0) {
        atRiskPaymentsArray[findIndex].current = Number(passData.total);
      } else {
        atRiskPaymentsArray.push({
          name: passData.name,
          current: Number(passData.total),
          last: 0,
        });
      }
    }
    for (let passData of lastMonthAtRiskData) {
      let findIndex = atRiskPaymentsArray.findIndex(
        (data) => data.name == passData.name
      );
      if (findIndex >= 0) {
        atRiskPaymentsArray[findIndex].last = Number(passData.total);
      } else {
        atRiskPaymentsArray.push({
          name: passData.name,
          last: Number(passData.total),
          current: 0,
        });
      }
    }
    let sortedAtRiskArray = atRiskPaymentsArray.sort(function (o1, o2) {
      return o1.name > o2.name ? 1 : o1.name < o2.name ? -1 : 0;
    });

    const partnerCodeList = partnerOnboardingCodes.map((data) => {
      const key = `partner-code-reports-${data.app_partner_onboarding_codes_id}`;

      return {
        label: data.partner_name,
        key: key,
      };
    });

    let totalLocationData = 0;
    let resultUserLocation = [];
    let totalBreakdownData = 0;

    if (generalTabReports?.customerLocationData) {
      resultUserLocation = generalTabReports?.customerLocationData.map(
        (row, index) => {
          totalLocationData = totalLocationData + Number(row.total);
          return {
            ...row,
            key: `report_location_item_${index}`,
            sr_no: index + 1,
          };
        }
      );
    }

    let resultUserBreakdown = [];
    if (generalTabReports?.customerBreakdownData) {
      resultUserBreakdown = generalTabReports?.customerBreakdownData.map(
        (row, index) => {
          totalBreakdownData = Number(row.totalPercentUsers);
          return {
            ...row,
            key: `report_breakdown_item_${index}`,
            sr_no: index + 1,
          };
        }
      );
    }

    let results = [];
    let totalMarketplaceClicks = 0;
    if (marketplaceClickInfo && marketplaceClickInfo.length > 0) {
      let month = moment().format("YYYY-MM");
      results = marketplaceClickInfo
        .filter((data) => data.year_month == month)
        .map((row, index) => {
          // if (row.year_month == month) {
          totalMarketplaceClicks =
            totalMarketplaceClicks + Number(row.total_clicks);

          return {
            ...row,
            key: `report_general_item_${index}`,
            sr_no: index + 1,
          };
          // }
        });
    }

    //financial tab calculation
    financialTableData.map((data) => {
      if (financialTabReports[data.identifier]) {
        data.metric = financialTabReports[data.identifier];
      } else {
        data.metric = "WIP";
      }
      return data;
    });

    let cantAffordToPay =
      consumerDutyTabClicks.length > 0
        ? consumerDutyTabClicks.filter(
          (data) => data.name === "I_can't_afford_to_pay"
        )
        : [];

    let lastMonthCantAfford = consumerDutyTabClicksLastMonth.filter(
      (data) => data.name === "I_can't_afford_to_pay"
    );

    // cant afford to pay
    for (let passData of cantAffordToPay) {
      let findIndex = cantAffordArray.findIndex(
        (data) => data.type == passData.type
      );
      if (findIndex >= 0) {
        cantAffordArray[findIndex].current = Number(passData.count);
      } else {
        cantAffordArray.push({
          type: passData.type,
          current: Number(passData?.count) || 0,
          last: 0,
        });
      }
    }
    for (let passData of lastMonthCantAfford) {
      let findIndex = cantAffordArray.findIndex(
        (data) => data.type == passData.type
      );
      if (findIndex >= 0) {
        cantAffordArray[findIndex].last = Number(passData.count);
      } else {
        cantAffordArray.push({
          type: passData.type,
          last: Number(passData?.count) || 0,
          current: 0,
        });
      }
    }
    let sortedCantAffordArray = cantAffordArray.sort(function (o1, o2) {
      return o1.name > o2.name ? 1 : o1.name < o2.name ? -1 : 0;
    });

    let speakToYourProviderCount = 0;
    let stepChangeCount = 0;
    let lostMyJobCount = 0;
    let cantAffordToPayCount = 0;
    let housingSupportTotalCount = 0;
    // let cantAffordToPayUserCount = 0;

    if (consumerDutyTabClicks.length > 0) {
      consumerDutyTabClicks.map((data) => {
        if (data.name === "speak_to_your_provider") {
          speakToYourProviderCount =
            speakToYourProviderCount + Number(data.user_id_length);
        }
        if (data.name === "speak_to_stepchange") {
          stepChangeCount = stepChangeCount + Number(data.user_id_length);
        }

        if (data.name === "I_lost_my_job") {
          lostMyJobCount = lostMyJobCount + Number(data.user_id_length);
        }

        if (data.name === "I_can't_afford_to_pay") {
          cantAffordToPayCount = cantAffordToPayCount + Number(data.count);
          // cantAffordToPayUserCount =
          //   cantAffordToPayUserCount + Number(data.user_id_length);
        }

        if (
          data.name === "I_can't_afford_to_pay" &&
          (data.type == "Rent" || data.type == "Mortgage")
        ) {
          housingSupportTotalCount =
            housingSupportTotalCount + Number(data.user_id_length);
        }
      });
    }

    consumerDutyTableData.map((data) => {
      if (data.identifier === "vulnerable_customers") {
        data.metric =
          Number(dashboard?.totalVulnerableCustomers) != 0 &&
            Number(dashboard?.totalActiveAccount) != 0
            ? (
              ((dashboard?.totalVulnerableCustomers || 0) /
                (dashboard?.totalActiveAccount || 0)) *
              100
            ).toFixed(2)
            : 0;
      } else if (data.identifier === "housing_support") {
        data.metric = housingSupportTotalCount || 0;
      } else if (data.identifier === "employment_support") {
        data.metric = lostMyJobCount || 0;
      } else if (data.identifier === "step_change_signpost") {
        data.metric = stepChangeCount || 0;
      } else if (data.identifier === "speak_to_your_provider") {
        data.metric = speakToYourProviderCount || 0;
      } else if (data.identifier === "users_eligigble_for_benefits") {
        data.metric = "WIP";
      } else if (data.identifier === "avg_amount_eligigble_for_benefits") {
        data.metric = "WIP";
      } else {
        if (consumerDutyTabReports[data.identifier]) {
          data.metric = consumerDutyTabReports[data.identifier] || 0;
        } else {
          data.metric = "0";
        }
      }
      return data;
    });

    collectionsReportingTableData.map((data) => {
      if (data.identifier === "total_cap_clicks") {
        data.metric = cantAffordToPayCount || 0;
      } else if (data.identifier === "total_partner_support_clicks") {
        data.metric = speakToYourProviderCount || 0;
      } else {
        if (collectionTabReports[data.identifier]) {
          data.metric = collectionTabReports[data.identifier] || 0;
        } else {
          data.metric = "0";
        }
      }
      return data;
    });

    // console.log("dashboard ==>", dashboard);
    // console.log("marketplaceClickInfo ==>", marketplaceClickInfo);
    return (
      <BasicLayout>
        <Row className="section-title">
          <div className="section-block-group">
            <Col md={6}>
              <h4>Reports</h4>
            </Col>
            <div className="report-div">
              <Button
                onClick={() => {
                  this.setState({
                    versionUpdateModal: true,
                  });
                }}
                type="primary"
                className="report-button"
              >
                Export report
              </Button>

              <Select
                className="report-dropdown"
                style={{ width: 150 }}
                dropdownMatchSelectWidth={false}
                value={partnerDropdownOption}
                onChange={this.handleDropdownItemClick}
                options={partnerCodeList.map((data) => ({
                  label: data.label,
                  value: data.key,
                }))}
              />
            </div>
          </div>
        </Row>
        <div style={{ marginLeft: "33px" }}>
          <div className="button-group">
            <Button
              onClick={() => this.handleButtonClick("GENERAL")}
              type="primary"
              shape="round"
              className="btn-grp"
              style={isGeneral ? { backgroundColor: "#112042" } : {}}
            >
              GENERAL
            </Button>
            <Button
              onClick={() => this.handleButtonClick("FINANCIAL")}
              type="primary"
              shape="round"
              className="btn-grp"
              style={isFinancial ? { backgroundColor: "#112042" } : {}}
            >
              FINANCIAL
            </Button>
            <Button
              onClick={() => this.handleButtonClick("COLLECTIONS")}
              type="primary"
              shape="round"
              className="btn-grp"
              style={isCollections ? { backgroundColor: "#112042" } : {}}
            >
              COLLECTIONS
            </Button>
            <Button
              onClick={() => this.handleButtonClick("CONSUMER")}
              type="primary"
              shape="round"
              className="btn-grp"
              style={isConsumerDuty ? { backgroundColor: "#112042" } : {}}
            >
              CONSUMER DUTY
            </Button>
          </div>
          {isGeneral && (
            <>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <Card title={"General"} className="report-custom-card">
                    <div>
                      <table style={{ marginTop: "-20px" }}>
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ width: "300px" }}
                            >
                              ITEM
                            </th>
                            <th className="table-header">{currentMonth}</th>
                            <th className="table-header">{lastMonth}</th>
                            <th className="table-header">{secondLastMonth}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {generalTableData.map((item) => (
                            <tr key={item.id}>
                              <td className="table-data">{item.item}</td>
                              <td className="table-data">
                                {dashboard[item.key]}
                              </td>
                              {/* <td className="table-data">{item.changeMonth}</td> */}
                              <td className="table-data">
                                {lastMonthAdminData?.["generalTabReport"]?.[
                                  item.key
                                ] || "N/A"}
                              </td>
                              {/* <td className="table-data">
                                {item.change6Month}
                              </td> */}
                              <td className="table-data">
                                {secondLastMonthAdminData?.[
                                  "generalTabReport"
                                ]?.[item.key] || "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Card
                className="report-custom-card"
                style={{ borderRadius: "20px", width: "387px" }}
                onClick={this.handleNpsFieldModal}
              >
                <p className="sun-card-title">NPS</p>
                <div className="perc-div">
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Row>
                      <Col md={12} className="sub-card">
                        <h5 className="title-text">Score</h5>
                        <h5 className="desc-text">
                          {consumerDutyTabReports?.nps_score || 0}%
                        </h5>
                      </Col>
                      <Col md={12} className="sub-card">
                        <h5 className="title-text">Rating</h5>
                        <h5 className="desc-text">
                          {consumerDutyTabReports?.nps_rating || "NA"}
                        </h5>
                      </Col>
                    </Row>
                  </div>
                </div>
                <p className="sun-card-title" style={{ marginTop: "39px" }}>
                  In-app rating
                </p>
                <div className="perc-div" style={{ marginBottom: "35px" }}>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Row>
                      <Col md={12} className="sub-card">
                        <h5 className="title-text">Favourable 👍</h5>
                        <h5 className="desc-text">
                          {dashboard["totalRecommededUp"]?.toFixed(2) || "NA"}%
                        </h5>
                      </Col>
                      <Col md={12} className="sub-card">
                        <h5 className="title-text">Negative 👎</h5>
                        <h5 className="desc-text">
                          {dashboard["totalRecommededDown"]?.toFixed(2) || "NA"}
                          %
                        </h5>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <Card
                    title={"Customer locations Breakdown"}
                    style={{ borderRadius: "20px" }}
                    className="report-custom-card"
                  >
                    <div>
                      <table style={{ marginTop: "-20px" }}>
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ width: "520px" }}
                            >
                              LOCATION
                            </th>
                            <th className="table-header">Total no. </th>
                            <th className="table-header">%</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resultUserLocation.map((item) => (
                            <tr key={item.key}>
                              <td className="table-data">{item.label}</td>
                              <td className="table-data">{item.total}</td>
                              <td className="table-data">
                                {(
                                  (Number(item.total) /
                                    Number(totalLocationData)) *
                                  100
                                ).toFixed(1)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <Card
                    title={"Feature usage"}
                    style={{ borderRadius: "20px" }}
                    className="report-custom-card"
                  >
                    <div>
                      <table style={{ marginTop: "-20px" }}>
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ width: "520px" }}
                            >
                              No. MARKETPLACE CLICKS
                            </th>
                            <th className="table-header">Total no. </th>
                            <th className="table-header">%</th>
                          </tr>
                        </thead>
                        <tbody>
                          {results.map((item) => (
                            <tr key={item.key}>
                              <td
                                className="table-data"
                                style={{ textTransform: "capitalize" }}
                              >
                                {item.page_name == "splurge"
                                  ? "Swear Jar"
                                  : item.page_name == "win_money"
                                    ? "Rewards"
                                    : item.page_name?.split("_")?.join(" ") || ""}
                              </td>
                              <td className="table-data">
                                {item.total_clicks}
                              </td>
                              <td className="table-data">
                                {Number(item.total_clicks) == 0
                                  ? 0
                                  : (
                                    (Number(item.total_clicks) /
                                      Number(totalMarketplaceClicks)) *
                                    100
                                  ).toFixed(1)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <Card
                    title={"Customer breakdown"}
                    style={{ borderRadius: "20px", marginBottom: "158px" }}
                    className="report-custom-card"
                  >
                    <div>
                      <table style={{ marginTop: "-20px" }}>
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ width: "520px" }}
                            >
                              PERSONAS
                            </th>
                            <th className="table-header">Total no. </th>
                            <th className="table-header">%</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resultUserBreakdown.map((item) => (
                            <tr key={item.key}>
                              <td className="table-data">{item.label}</td>
                              <td className="table-data">{item.total}</td>
                              <td className="table-data">
                                {(
                                  (Number(item.total) /
                                    Number(totalBreakdownData)) *
                                  100
                                ).toFixed(1)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          )}

          {isFinancial && (
            <>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <Card
                    title={"Financial"}
                    style={{
                      borderRadius: "20px",
                      width: "1112px",
                      marginBottom: "58px",
                    }}
                    className="report-custom-card"
                  >
                    <div>
                      <table style={{ marginTop: "-20px" }}>
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ width: "700px" }}
                            >
                              ITEM
                            </th>
                            <th className="table-header">{currentMonth}</th>
                            <th className="table-header">{lastMonth}</th>
                            <th className="table-header">{secondLastMonth}</th>
                            <th className="table-header">{thirdLastMonth}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {financialTableData.map((item) => (
                            <tr key={item.id}>
                              <td className="table-data">{item.item}</td>
                              <td className="table-data">{item.metric}</td>
                              <td className="table-data">
                                {lastMonthAdminData?.["financialTabReport"]?.[
                                  item.identifier
                                ] ||
                                  item?.changeMonth ||
                                  "N/A"}
                              </td>
                              <td className="table-data">
                                {secondLastMonthAdminData?.[
                                  "financialTabReport"
                                ]?.[item.identifier] || "N/A"}
                              </td>
                              <td className="table-data">
                                {thirdLastMonthAdminData?.[
                                  "financialTabReport"
                                ]?.[item.identifier] || "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          )}

          {isCollections && (
            <>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <Card
                    title={"Collections reporting"}
                    style={{ borderRadius: "20px", marginBottom: "59px" }}
                    className="report-custom-card"
                  >
                    <div>
                      <table style={{ marginTop: "-20px" }}>
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ width: "700px" }}
                            >
                              ITEM
                            </th>
                            <th className="table-header">{currentMonth}</th>
                            <th className="table-header">{lastMonth}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {collectionsReportingTableData.map((item) => (
                            <tr key={item.id}>
                              <td className="table-data">{item.item}</td>
                              <td className="table-data">{item.metric}</td>
                              <td className="table-data">
                                {lastMonthAdminData?.["collectionTabReport"]?.[
                                  item.identifier
                                ] ||
                                  item?.changeMonth ||
                                  "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          )}

          {isConsumerDuty && (
            <>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <Card
                    title={"Consumer Duty"}
                    style={{ borderRadius: "20px", width: "999px" }}
                    className="report-custom-card"
                  >
                    <div>
                      <table style={{ marginTop: "-20px" }}>
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ width: "850px" }}
                            >
                              ITEM
                            </th>
                            <th className="table-header">
                              {moment().format("MMM YYYY")}
                            </th>
                            <th className="table-header">{lastMonth}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {consumerDutyTableData.map((item) => (
                            <tr
                              key={item.id}
                              onClick={() => {
                                if (
                                  item?.identifier ===
                                  "users_sleeping_better" ||
                                  item?.identifier ===
                                  "users_reduced_money_worries" ||
                                  item?.identifier ===
                                  "users_good_outcome_personal" ||
                                  item?.identifier ===
                                  "users_superfi_easy_use" ||
                                  item?.identifier ===
                                  "users_superfi_easy_comms" ||
                                  item?.identifier ===
                                  "users_superfi_mental_wellbeing" ||
                                  item?.identifier ===
                                  "users_reduced_bill_provider_support" ||
                                  item?.identifier ===
                                  "users_improved_pay_bills_time" ||
                                  item?.identifier ===
                                  "users_improved_fin_wellbeing"
                                ) {
                                  this.handleConsumerDutyModal(item);
                                }
                              }}
                            >
                              <td className="table-data">{item.item}</td>
                              <td className="table-data">{item.metric}</td>
                              <td className="table-data">
                                {lastMonthAdminData?.[
                                  "consumerDutyTabReport"
                                ]?.[item.identifier] ||
                                  item?.changeMonth ||
                                  "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <Card
                    title={"Missed payments"}
                    style={{ borderRadius: "20px" }}
                    className="report-custom-card"
                  >
                    <div>
                      <table style={{ marginTop: "-20px" }}>
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ width: "520px" }}
                            >
                              ITEM
                            </th>
                            <th className="table-header">{currentMonth}</th>
                            <th className="table-header">{lastMonth}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedOverdueArray.map((item, index) => (
                            <tr key={"overdue_data_" + index}>
                              <td className="table-data">{item.name}</td>
                              <td className="table-data">
                                {item?.current || 0}
                              </td>
                              <td className="table-data">{item?.last || 0}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <Card
                    title={"I can't afford to pay"}
                    style={{ borderRadius: "20px" }}
                    className="report-custom-card"
                  >
                    <div>
                      <table style={{ marginTop: "-20px" }}>
                        {sortedCantAffordArray.length > 0 ? (
                          <thead>
                            <tr>
                              <th
                                className="table-header"
                                style={{ width: "520px" }}
                              >
                                ITEM
                              </th>
                              <th className="table-header">{currentMonth}</th>
                              <th className="table-header">{lastMonth}</th>
                            </tr>
                          </thead>
                        ) : (
                          ""
                        )}
                        {sortedCantAffordArray.length > 0 ? (
                          <tbody>
                            {sortedCantAffordArray.map((item, index) => (
                              <tr key={`cant-afford-to-pay-${index}`}>
                                <td className="table-data">
                                  {item.type == "null" ? "Others" : item.type}
                                </td>
                                <td className="table-data">
                                  {item.current || 0}
                                </td>
                                <td className="table-data">{item.last || 0}</td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          "No data found!"
                        )}
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <Card
                    title={"At risk payments"}
                    style={{ borderRadius: "20px", marginBottom: "59px" }}
                    className="report-custom-card"
                  >
                    <div>
                      <table style={{ marginTop: "-20px" }}>
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ width: "520px" }}
                            >
                              ITEM
                            </th>
                            <th className="table-header">{currentMonth}</th>
                            <th className="table-header">{lastMonth}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedAtRiskArray.map((item, index) => (
                            <tr key={"at_risk_data_" + index}>
                              <td className="table-data">{item.name}</td>
                              <td className="table-data">
                                {item?.current || 0}
                              </td>
                              <td className="table-data">{item?.last || 0}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
        <CustomModal
          className={"signup-setting-modal"}
          visible={versionUpdateModal}
          onClose={() => {
            this.handleCloseModal();
          }}
        >
          <Form
            ref={this.formRef}
            size="large"
            initialValues={{
              version: "",
            }}
            onFinish={(val) => {
              this.exportReport(
                totalBreakdownData,
                totalLocationData,
                totalMarketplaceClicks,
                resultUserLocation,
                resultUserBreakdown,
                results,
                consumerDutyTableData,
                val,
                cantAffordToPayCount,
                speakToYourProviderCount,
                stepChangeCount,
                lostMyJobCount,
                housingSupportTotalCount,
                sortedOverdueArray,
                sortedAtRiskArray,
                sortedCantAffordArray
              );
            }}
            layout="vertical"
            className="form-login"
          >
            <p className="txt-title">Version Number</p>

            <div className="custom-form-container">
              <Form.Item
                name="version"
                label="Version number"
                rules={[
                  {
                    required: true,
                    message: "Please enter version number",
                  },
                ]}
              >
                <Input placeholder="0" type="text" />
              </Form.Item>
            </div>

            <Button
              className="text-gray"
              htmlType="submit"
              type="primary"
              block
            >
              Save
            </Button>
          </Form>
        </CustomModal>
        <CustomModal
          className={"signup-setting-modal"}
          visible={fieldUpdateModalNps || fieldUpdateModalConsumer}
          onClose={() => {
            this.handleCloseModal();
          }}
        >
          <Form
            ref={this.formRef}
            size="large"
            initialValues={{
              score: null,
              rating: "",
            }}
            onFinish={(val) => {
              this.submitForm(val);
            }}
            layout="vertical"
            className="form-login"
          >
            <p className="txt-title">
              {consumerTabMetric?.identifier == "users_sleeping_better"
                ? "% of users sleeping better"
                : consumerTabMetric?.identifier == "users_reduced_money_worries"
                  ? "% of users with reduced money worries"
                  : consumerTabMetric?.identifier == "users_good_outcome_personal"
                    ? "% of users that felt they received good outcome based on their personal situation"
                    : consumerTabMetric?.identifier == "users_superfi_easy_use"
                      ? "% of users that felt SuperFi was easy to use"
                      : consumerTabMetric?.identifier == "users_superfi_easy_comms"
                        ? "% of users that felt SuperFi app & comms were easy to understand"
                        : consumerTabMetric?.identifier ==
                          "users_superfi_mental_wellbeing"
                          ? "% of users that feel SuperFi has improved their mental wellbeing"
                          : consumerTabMetric?.identifier ==
                            "users_reduced_bill_provider_support"
                            ? "% of users feel that SuperFi has reduced the need to get support from their bill provider"
                            : consumerTabMetric?.identifier ==
                              "users_improved_pay_bills_time"
                              ? "% of users feel that SuperFi has improved ability to pay bills on time"
                              : consumerTabMetric?.identifier ==
                                "users_improved_fin_wellbeing"
                                ? "% of users feel SuperFi has improved financial wellbeing"
                                : "NPS score and rating"}
            </p>
            <p className="txt-info">
              {fieldUpdateModalNps ? "SuperFi NPS Scoring" : ""}
            </p>

            {fieldUpdateModalConsumer && (
              <div className="custom-form-container">
                <Form.Item
                  name="score"
                  label="Metric"
                  rules={[
                    {
                      required: true,
                      message: "Please enter metric",
                    },
                  ]}
                >
                  <Input addonAfter="%" placeholder="0" type="text" />
                </Form.Item>
              </div>
            )}

            {fieldUpdateModalNps && (
              <div>
                <div className="custom-form-container">
                  <Form.Item
                    name="score"
                    label="Score"
                    rules={[
                      {
                        required: true,
                        message: "Please enter score",
                      },
                    ]}
                  >
                    <Input addonAfter="%" placeholder="0" type="text" />
                  </Form.Item>
                </div>

                <div className="custom-form-container">
                  <Form.Item
                    name="rating"
                    label="Rating"
                    rules={[
                      {
                        required: true,
                        message: "Please enter rating",
                      },
                    ]}
                  >
                    <Input placeholder="" type="text" />
                  </Form.Item>
                </div>
              </div>
            )}

            <Button
              className="text-gray"
              htmlType="submit"
              type="primary"
              block
            >
              Save
            </Button>
          </Form>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    // reports: state.reports,
    dashboard: state.dashboard,
    marketplaceClickInfo: state.marketplaceClickInfo,
    generalTabReports: state.generalTabReports,
    financialTabReports: state.financialTabReports,
    consumerDutyTabReports: state.consumerDutyTabReports,
    consumerDutyTabClicks: state.consumerDutyTabClicks,
    partnerOnboardingCodes: state.partnerOnboardingCodes,
    collectionTabReports: state.collectionTabReports,
    lastMonthAdminData: state.lastMonthAdminData,
    secondLastMonthAdminData: state.secondLastMonthAdminData,
    thirdLastMonthAdminData: state.thirdLastMonthAdminData,
    consumerDutyTabClicksLastMonth: state.consumerDutyTabClicksLastMonth,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
