import { Button, Col, Row, Form, Input, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import CustomModal from "../../components/CustomModal";

import { notifyError, notifySuccess } from "../../common/notification";

// It's shows the card catalog info
class cashbackRetailers extends Component {
  state = {
    addModal: false,
    editModal: false,
    deleteModal: false,
    deleteId: "",
    selectedRecordId: null,
    selectedRecordData: null,
    selectedFile: null,
    selectedImageSrc: null,
  };

  componentDidMount() {
    this.props.cashbackRetailersList();
  }

  handleEditModal = (Id, data, evt) => {
    const { editModal } = this.state;

    if (Id && data) {
      this.setState({
        editModal: !editModal,
        selectedFile: null,
        selectedImageSrc: null,
        selectedRecordId: Id,
        selectedRecordData: data,
      });
    } else {
      this.setState({
        editModal: false,
        addModal: false,
        selectedFile: null,
        selectedImageSrc: null,
        selectedRecordId: null,
        selectedRecordData: null,
      });
    }
  };

  handleAddModal = () => {
    const { addModal } = this.state;

    this.setState({
      addModal: !addModal,
      selectedFile: null,
      selectedImageSrc: null,
      selectedRecordId: null,
      selectedRecordData: null,
    });
  };

  // Add, Edit and Delete Icons
  submitForm = (action, values) => {
    const { selectedFile, selectedRecordId } = this.state;

    var formData = new FormData();
    formData.append("action", action);
    formData.append("data", JSON.stringify(values));
    formData.append("id", selectedRecordId);

    if (selectedFile) {
      if (!selectedFile.type.includes("image/")) {
        notifyError("Please upload image file");
        return false;
      }
      formData.append("file", selectedFile);
    }
    this.props.addUpdateRetailer(formData).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({
          editModal: false,
          addModal: false,
          selectedRecordData: null
        });
        notifySuccess(res?.data?.message || "Done");
        this.props.cashbackRetailersList();
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  handleFile = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({
        selectedImageSrc: [reader.result],
      });
    }.bind(this);
    this.setState({ selectedFile: file });
  };

  handleDeleteModal = (id) => {
    const { deleteModal } = this.state;
    this.setState({
      deleteModal: !deleteModal,
      deleteId: id,
    });
  };

  submitUserAction = () => {
    this.setState({ deleteModal: false });
    this.props
      .deleteRetailer({
        action: "delete",
        id: this.state.deleteId,
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          this.setState({
            deleteUserModal: false,
            deleteId: "",
            editModal: false,
          });
          notifySuccess(res?.data?.message || "Done");
          this.props.cashbackRetailersList();
        } else {
          var errorMessage =
            res?.data?.message || res?.err?.message || "Something went wrong";
          notifyError(errorMessage);
        }
      });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "#",
      dataIndex: "cashback_voucher_data_id",
      key: "cashback_voucher_data_id",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: '10px' }}>{record.sr_no || ""}</p>
      ),
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (_text, record) => (
        <p className="brand-name">{record?.brand_name || ""}</p>
      ),
    },
    {
      title: "Brand Logo",
      dataIndex: "brand_logo",
      key: "brand_logo",
      render: (_text, record) => (
        <img
          src={record.brand_logo ? record.brand_logo : 'https://via.placeholder.com/60x60?text=Brand'}
          alt="brand logo"
          style={{
            width: "45px",
            height: "45px",
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: "50%"
          }}
        />
      ),
    },

    {
      title: "Eligible Cashback",
      dataIndex: "discount",
      key: "discount",
      render: (_text, record) => (
        <p className="brand-name">{record.discount ? `${record.discount}%` : ""}</p>
      ),
    },

    {
      title: "Offered Cashback",
      dataIndex: "superfi_discount",
      key: "superfi_discount",
      render: (_text, record) => (
        <p className="brand-name">{record.superfi_discount ? `${record.superfi_discount}%` : "--"}</p>
      ),
    },

    {
      title: "Tillo or Mannual",
      dataIndex: "tillo_or_mannual",
      key: "tillo_or_mannual",
      render: (_text, record) => (
        <p className="brand-name">{record?.slug == null ? 'Manual' : 'Tillo'}</p>
      ),
    },

    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) => {
                this.handleDeleteModal(record.cashback_voucher_data_id);
              }}
            >
              Delete
            </span>
            <span
              onClick={(e) =>
                this.handleEditModal(
                  record.cashback_voucher_data_id,
                  record,
                  e
                )
              }
            >
              Edit
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { cashbackRetailerList } = this.props;
    const {
      editModal,
      selectedImageSrc,
      selectedRecordData,
      deleteModal,
      addModal,
    } = this.state;
    let results = [];
    console.log('selected Record =====>', selectedRecordData)
    if (cashbackRetailerList && cashbackRetailerList.length > 0) {
      for (var i = 0; i < cashbackRetailerList.length; i++) {
        var key = `user-cashback-voucher-${cashbackRetailerList[i].cashback_voucher_data_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...cashbackRetailerList[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <div className="section-block-group">
            <Col md={26}>
              <h4>{`Cashback Retailers (${results.length})`}</h4>
            </Col>
            <div className="retailer-div">
              <Button
                onClick={''}
                type="primary"
                className="retailers-button"
              >
                Sync with Tillo
              </Button>

              <Button
                onClick={() => {
                  this.setState({
                    addModal: true,
                  });
                }}
                type="primary"
                className="retailers-button"
                style={{ marginRight: '0px' }}
              >
                Add Retailer Mannually
              </Button>


            </div>
          </div>
        </Row>
        <Row className="card-table partner-table">
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
          />
        </Row>

        {((editModal && selectedRecordData) || addModal) && (

          <CustomModal
            className={"signup-setting-modal"}
            visible={editModal || addModal}
            onClose={this.handleEditModal}
          >
            <Form
              size="large"
              initialValues={selectedRecordData}
              onFinish={(val) => this.submitForm(addModal ? "add" : "edit", val)}
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Retailer Information</p>
              <div className="custom-form-container">
                <Form.Item
                  name="brand_name"
                  label="Brand Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Brand name",
                    },
                  ]}
                >
                  <Input
                    disabled={editModal && selectedRecordData?.slug != null ? true : false}
                    placeholder="Brand Name"
                    type="text"
                    value={selectedRecordData?.brand_name || ""}
                  />
                </Form.Item>

                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label htmlFor="Voucher visual" title="Voucher visual">
                      Brand Logo
                    </label>
                    <div className="custom-file-input">
                      <img
                        src={
                          selectedImageSrc ||
                          selectedRecordData?.brand_logo ||
                          `https://via.placeholder.com/60x60?text=Brand`
                        }
                        alt="brand logo"
                        style={{ borderRadius: '50%', paddingRight: '0px' }}
                      />
                      <input type="file" onChange={this.handleFile} />
                      <span style={{ marginLeft: '10px' }}>Edit</span>
                    </div>
                  </div>
                </div>

                <div className="ant-row" style={{ gap: '10px' }}>
                  <Form.Item
                    name="discount"
                    label="Eligible Cashback"
                    className="ant-col"
                    style={{ flex: '0 0 48%' }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Eligible Cashback",
                      },
                    ]}
                  >
                    <Input
                      placeholder="%"
                      type="text"
                      value={selectedRecordData?.discount ? `${selectedRecordData.discount}%` : ""}
                      disabled={editModal && selectedRecordData?.slug != null ? true : false}
                      style={{ paddingRight: '20px' }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="superfi_discount"
                    label="Cashback Offered"
                    className="ant-col"
                    style={{ flex: '0 0 48%' }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Cashback Offered",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const discount = parseFloat(getFieldValue('discount')); // Get the Eligible Cashback
                          const superfiDiscount = parseFloat(value); // Cashback Offered

                          // Check if Cashback Offered is less than Eligible Cashback
                          if (!value || superfiDiscount <= discount) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('Cashback Offered must be less than or equal to Eligible Cashback'));
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="%"
                      type="text"
                      value={selectedRecordData?.superfi_discount ? `${selectedRecordData.superfi_discount}%` : ""}
                      style={{ paddingLeft: '20px' }}
                    />
                  </Form.Item>
                </div>


                <Form.Item
                  name="category"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Category",
                    },
                  ]}
                >
                  <Input
                    placeholder="Entertainment"
                    type="text"
                    value={selectedRecordData?.category ? selectedRecordData.category : ""}
                  />
                </Form.Item>

                <Form.Item
                  name="how_to_use"
                  label="How to use URL"
                  rules={[
                    {
                      required: false,
                      message: "Please enter a URL",
                    },
                    {
                      pattern: new RegExp(/^(https?:\/\/)?([a-zA-Z0-9\-_]+\.)+[a-zA-Z]{2,6}(\/[\w\-.~:?#[\]@!$&'()*+,;=]*)*\/?$/),
                      message: "Please enter a valid URL",
                    },
                  ]}
                >
                  <Input
                    placeholder="X"
                    type="text"
                    value={selectedRecordData?.how_to_use ? selectedRecordData.how_to_use : ""}
                  />
                </Form.Item>

                <Form.Item
                  name="redemption_tips"
                  label="Redemption Tips URL"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a Redemption tips",
                    }
                  ]}
                >
                  <Input
                    placeholder="Amazon"
                    type="text"
                    value={selectedRecordData?.redemption_tips ? selectedRecordData.redemption_tips : ""}

                  />
                </Form.Item>
              </div>

              <Button className="text-gray" htmlType="submit" type="primary" block>
                Save
              </Button>
            </Form>
          </CustomModal>

        )
        }
        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteModal}
          onClose={this.handleDeleteModal}
        >
          <p className="txt-title">Delete this Retailer?</p>
          <p className="txt-info">This change cannot be undone.</p>

          <Button
            type="primary"
            block
            onClick={() => this.submitUserAction("delete")}
          >
            Delete
          </Button>
        </CustomModal>
      </BasicLayout >
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    cashbackRetailerList: state.cashbackRetailerList
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(cashbackRetailers));
