import moment from "moment";
import isEqual from "lodash.isequal";
import config from "../config";
import * as API from "./../services/api";
import jwt from "jwt-decode";

export const isSuperAdmin = () => {
  const token = API.tokenStorage.get();
  if (token) {
    const admin = jwt(token);
    if (admin && admin.adminRole && admin.adminRole === "admin") {
      return true;
    }
  }
  return false;
};

// built in functions for reducer and api calling
export const delay = (fn, timeout) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(fn());
      } catch (e) {
        reject(e);
      }
    }, timeout);
  });
};

export const getImage = (path) => {
  var apiRoot = config.API_URL;
  var filePath;
  if (path && path.startsWith("http")) {
    filePath = path;
  } else {
    filePath = apiRoot + "/api/image/" + path;
  }

  return filePath;
};

export const partial = (fn) => {
  const len = fn.length;
  const arbitary =
    (curArgs, leftArgCnt) =>
    (...args) => {
      if (args.length >= leftArgCnt) {
        return fn.apply(null, curArgs.concat(args));
      }

      return arbitary(curArgs.concat(args), leftArgCnt - args.length);
    };

  return arbitary([], len);
};

export const reduceRight = (fn, initial, list) => {
  var ret = initial;

  for (let i = list.length - 1; i >= 0; i--) {
    ret = fn(list[i], ret);
  }

  return ret;
};

export const compose = (...args) => {
  return reduceRight(
    (cur, prev) => {
      return (x) => cur(prev(x));
    },
    (x) => x,
    args
  );
};

export const map = partial((fn, list) => {
  var result = [];

  for (let i = 0, len = list.length; i < len; i++) {
    result.push(fn(list[i]));
  }

  return result;
});

export const on = partial((key, fn, dict) => {
  if (Array.isArray(dict)) {
    return [...dict.slice(0, key), fn(dict[key]), ...dict.slice(key + 1)];
  }

  return Object.assign({}, dict, {
    [key]: fn(dict[key]),
  });
});

export const updateIn = partial((keys, fn, obj) => {
  const updater = compose.apply(
    null,
    keys.map((key) => on(key))
  );
  return updater(fn)(obj);
});

export const setIn = partial((keys, value, obj) => {
  const updater = compose.apply(
    null,
    keys.map((key) => on(key))
  );
  return updater(() => value)(obj);
});

export const getIn = partial((keys, obj) => {
  return keys.reduce((prev, key) => {
    if (!prev) return prev;
    return prev[key];
  }, obj);
});

export const pick = (keys, obj) => {
  return keys.reduce((prev, key) => {
    prev[key] = obj[key];
    return prev;
  }, {});
};

export const uid = () => {
  return (
    "" +
    new Date() * 1 +
    "." +
    Math.floor(Math.random() * 10000000).toString(16)
  );
};

export const flatten = (list) => {
  return [].concat.apply([], list);
};

export const cn = (...args) => {
  return args
    .reduce((prev, cur) => {
      if (typeof cur === "string") {
        prev.push(cur);
      } else {
        Object.keys(cur).forEach((item) => {
          if (cur[item]) {
            prev.push(item);
          }
        });
      }

      return prev;
    }, [])
    .join(" ");
};

export const capitalize = (str) => {
  return str.substr(0, 1).toUpperCase() + str.substr(1).toLowerCase();
};

export const contains = (str, pattern) =>
  str && str.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;

export const and = (...list) => list.reduce((prev, cur) => prev && cur, true);

export const or = (...list) => list.reduce((prev, cur) => prev || cur, false);

export const sum = (...list) => list.reduce((prev, cur) => prev + cur, 0);

export const formatTime = (d) => {
  const padZero = (n) => (n < 10 ? "0" + n : "" + n);
  const dd = padZero(d.getDate());
  const mm = padZero(d.getMonth() + 1);
  const hh = padZero(d.getHours());
  const tt = padZero(d.getMinutes());

  return `${dd}/${mm}/${d.getFullYear()} ${hh}:${tt}`;
};

export const str2Moment = partial((keys, obj) => {
  const ret = { ...obj };

  keys.forEach((key) => {
    if (ret[key]) {
      ret[key] = moment(ret[key]);
    }
  });

  return ret;
});

export const moment2Str = partial((keys, obj) => {
  const ret = { ...obj };
  const formatDate = (d) =>
    [d.getFullYear(), d.getMonth() + 1, d.getDate()].join("-");

  keys.forEach((key) => {
    if (ret[key] && ret[key].toDate) {
      ret[key] = formatDate(ret[key].toDate());
    }
  });

  return ret;
});

export const objDiff = (obj1, obj2, keys, needSafe) => {
  const ks = Array.isArray(keys) ? keys : Object.keys(obj1);

  return ks.reduce((prev, key) => {
    if (needSafe && (obj2[key] === null || obj2[key] === undefined)) {
      return prev;
    }

    if (!isEqual(obj1[key], obj2[key])) {
      prev[key] = obj2[key];
    }

    return prev;
  }, {});
};

export const zipWith = (fn, ...rest) => {
  const result = [];
  const len = Math.min(...rest.map((list) => list.length));

  for (let i = 0; i < len; i++) {
    result.push(fn(...rest.map((list) => list[i])));
  }

  return result;
};

export const objMap = (fn, obj) => {
  return Object.keys(obj).reduce((prev, key) => {
    prev[key] = fn(obj[key], key, obj);
    return prev;
  }, {});
};

export const withCachedPromise = (timeout, fn) => {
  let lastCalled = 0;
  let cachedPromise = null;

  return () => {
    if (cachedPromise && new Date() * 1 - lastCalled < timeout) {
      return cachedPromise;
    }

    lastCalled = new Date() * 1;
    cachedPromise = fn();

    cachedPromise.catch((e) => {
      cachedPromise = null;
    });

    return cachedPromise;
  };
};

export const formatPrice = (price, currency) => {
  let cur = currency === undefined ? "GBP" : currency;
  let priceDefine = price === undefined ? 0 : price;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: cur,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(priceDefine);
};

export const getDebtSuggestionData = (card, arrData) => {
  if (card.user_card_id) {
    let findIndex = arrData.findIndex(
      (data) => data?.user_card_id === card?.user_card_id
    );
    return findIndex >= 0 ? arrData[findIndex].suggested_payment : "- -";
  }
  if (card.klarna_id) {
    let findIndex = arrData.findIndex(
      (data) => data?.klarna_id === card?.klarna_id
    );
    return findIndex >= 0 ? arrData[findIndex].suggested_payment : "- -";
  }

  if (card.user_overdraft_account_id) {
    let findIndex = arrData.findIndex(
      (data) =>
        data?.user_overdraft_account_id === card?.user_overdraft_account_id
    );
    return findIndex >= 0 ? arrData[findIndex].suggested_payment : "- -";
  }
};

export const getPaidTowardsData = (card, arrData) => {
  if (card.user_card_id) {
    let findIndex = arrData.findIndex(
      (data) => data?.user_card_id === card?.user_card_id
    );
    return findIndex >= 0 ? arrData[findIndex].paid_amount : "- -";
  }
  if (card.klarna_id) {
    let findIndex = arrData.findIndex(
      (data) => data?.klarna_id === card?.klarna_id
    );
    return findIndex >= 0 ? arrData[findIndex].paid_amount : "- -";
  }

  if (card.user_overdraft_account_id) {
    let findIndex = arrData.findIndex(
      (data) =>
        data?.user_overdraft_account_id === card?.user_overdraft_account_id
    );
    return findIndex >= 0 ? arrData[findIndex].paid_amount : "- -";
  }
};

export const getFollowedSuggestionNumber = (cardArr) => {
  let number = 0;
  for (let data of cardArr) {
    if (Number(data.paid_amount) >= Number(data.suggested_payment)) {
      number++;
    }
  }
  return number;
};

export const getPrioritisedDebtInfo = (cardArr) => {
  if (cardArr.length > 0) {
    let priorityPay = Number(cardArr[0].paid_amount);
    let returnPriority =
      Number(cardArr[0].paid_amount) === 0 || cardArr[0].paid_amount === "0.00"
        ? false
        : true;

    for (let data of cardArr) {
      if (Number(data.paid_amount) > priorityPay) {
        returnPriority = false;
      }
    }
    if (returnPriority) {
      return "✅";
    } else {
      return "x";
    }
  } else {
    return "x";
  }
};

export const formatPerc = (decimal) => {
  return Math.round(100 * decimal);
};

export const getNumberWithOrdinal = (n) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

//This for trim overdraft account number in 4 digits
export const trimAccountNumber = (value, numbers = 4) => {
  if (value && value.length > numbers) {
    return value.substring(value.length - numbers, value.length);
  } else {
    return value ? value : "";
  }
};

export const truncate = (input, length) =>
  input.length > length ? `${input.substring(0, length)}...` : input;

export const genders = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "intersex", label: "Intersex" },
  { value: "trans", label: "Trans" },
  { value: "non_conforming", label: "Non-conforming" },
  { value: "prefer_not_to_say", label: "Prefer not to say" },
  { value: "other", label: "Other" },
];
export const getGender = (gender) => {
  var gen = genders.find((el) => el.value === gender);
  if (gen && gen.label) {
    return gen.label;
  }
  return "-";
};

//This drop down data for the select gender
export const livePlaces = [
  { value: "north_east", label: "North East" },
  { value: "north_west", label: "North West" },
  { value: "yorkshire_and_humberside", label: "Yorkshire and Humberside" },
  { value: "east_midlands", label: "East Midlands" },
  { value: "west_midlands", label: "West Midlands" },
  { value: "east_of_england", label: "East of England" },
  { value: "london", label: "London" },
  { value: "south_east", label: "South East" },
  { value: "south_west", label: "South West" },
];

export const livingSituation = [
  { value: "single", label: "Single" },
  { value: "with_parents", label: "Living with parents" },
  { value: "with_partner", label: "Living with partner" },
  { value: "single_with_children", label: "Single, living with children" },
  { value: "with_partner_children", label: "With partner and children" },
];

export const housingStatusList = [
  { value: "mortgage", label: "Mortgage" },
  { value: "renting", label: "Renting" },
  { value: "board_lodging", label: "Board / lodging" },
  { value: "own_property_outright", label: "Own property outright" },
  {
    value: "renting_separate_mortgage",
    label: "Renting with a separate mortgage",
  },
  { value: "social_housing", label: "Social housing" },
  { value: "other", label: "Other" },
];

export const currentHouseHoldList = [
  { value: "no_children_under_18", label: "No children under 18" },
  { value: "one_children_under_18", label: "One child under 18" },
  { value: "two_children_under_18", label: "Two children under 18" },
  { value: "three_children_under_18", label: "Three children under 18" },
  { value: "four_children_under_18", label: "Four children under 18" },
  { value: "five_children_under_18", label: "Five children under 18" },
  {
    value: "more_children_under_18",
    label: "More than five children under 18",
  },
  { value: "expecting_9_month", label: "Expecting a child within 9 months" },
];

export const employmentStatus = [
  { value: "benefits", label: "Benefits" },
  { value: "part_time_employed", label: "Part time employed" },
  { value: "full_time_employed", label: "Full time employed" },
  {
    value: "not_working_due_illness",
    label: "Not working due to illness / disability",
  },
  { value: "self_employed", label: "Self employed" },
  { value: "retired", label: "Retired" },
  { value: "student", label: "Student" },
  { value: "unemployed", label: "Unemployed" },
  { value: "other", label: "Other" },
];

export const reasonOfUsing = [
  {
    value: "managing_money",
    label: "I want guidance on managing my money better",
  },
  {
    value: "bill_increasing",
    label: "I want to be prepared for bills increasing",
  },
  {
    value: "worried_about_parents",
    label: "I'm worried about being behind on my payments",
  },
  { value: "control_finances", label: "I'm not in control of my finances" },
  {
    value: "pressure_creditors",
    label: "I feel under pressure from my creditors",
  },
  {
    value: "anxious_repossessions",
    label: "I'm anxious about bailiffs or repossessions",
  },
];

export const financialDifficulties = [
  {
    value: "addiction",
    label: "Addiction",
    dropDownTitle: "What's your addiction?",
    data: [
      { value: "alcohol", label: "Alcohol" },
      { value: "drugs", label: "Drugs" },
      { value: "gambling", label: "Gambling" },
      { value: "other", label: "Other" },
    ],
  },
  { value: "bereavement", label: "Bereavement" },
  { value: "cost_living_increase", label: "Cost of living  increase" },
  { value: "covid", label: "Covid" },
  { value: "mental_issue", label: "Mental health issue" },
  { value: "health_issue", label: "Health issue - Diagnosed" },
  { value: "injury", label: "Injury" },
  { value: "irregular_income", label: "Irregular income" },
  { value: "pregnancy_childbirth", label: "Pregnancy or childbirth" },
  { value: "reduced_income_benefits", label: "Reduced income or benefits" },
  { value: "seperation_divorce", label: "Seperation or divorce" },
  { value: "taken_debt", label: "Taken debt out for someone else" },
  { value: "unemployment_redundancy", label: "Unemployment or redundancy" },
  { value: "unexpected_expense", label: "Unexpected expense" },
  { value: "other", label: "Other" },
];

export const currentlyManagingRent = [
  {
    value: "behind_with_payments",
    label: "I've fallen behind with my payments",
  },
  {
    value: "borrowing_or_credit",
    label: "I'm borrowing or using credit to pay",
  },
  {
    value: "worried_about_payments",
    label: "I'm ok for now but worried about upcoming payments",
  },
  {
    value: "want_to_save",
    label: "I'm ok for now but want to save more",
  },
];

export const allFactorsList = [
  {
    value: "reduced_income",
    label: "Reduced income",
  },
  {
    value: "refused_benefits",
    label: "Refused benefits",
  },
  {
    value: "stopped_delayed",
    label: "Benefits stopped or delayed",
  },
  {
    value: "benefits_incorrectly",
    label: "Benefits incorrectly paid",
  },
  {
    value: "debt_arrears",
    label: "Debt/arrears",
  },
  {
    value: "unpaid_bills",
    label: "Unpaid bills",
  },
  {
    value: "pay_for_food",
    label: "Can't pay for food",
  },
  {
    value: "gas_electric",
    label: "Can't pay for gas/electric",
  },
  {
    value: "rent_deposit",
    label: "Can't pay for rent/deposit",
  },
  {
    value: "risk_of_eviction",
    label: "Risk of eviction",
  },
  {
    value: "disability",
    label: "Disability",
  },
  {
    value: "health_issue",
    label: "Serious health issue",
  },
  {
    value: "gambling",
    label: "Gambling",
  },
  {
    value: "controlling_money",
    label: "Another person is controlling my money",
  },
  {
    value: "taken_debt",
    label: "I've taken our debt for someone else",
  },
  {
    value: "bayliff_action",
    label: "Bayliff action",
  },
  {
    value: "court_summons",
    label: "Court summons",
  },
  {
    value: "none_above",
    label: "None of the above",
  },
];

export const behindPaymentsList = [
  {
    value: "rent",
    label: "Rent",
  },
  {
    value: "mortgage",
    label: "Mortgage",
  },
  {
    value: "council_tax",
    label: "Council tax",
  },
  {
    value: "energy_bill",
    label: "Energy bill",
  },
  {
    value: "water_bill",
    label: "Water bill",
  },
  {
    value: "TV_licence",
    label: "TV licence",
  },
  {
    value: "secured_loan",
    label: "Secured loan (eg. from a bank)",
  },
  {
    value: "credit_store_card",
    label: "Credit card or store card",
  },
  {
    value: "catalogue_loan",
    label: "Catalogue loan",
  },
  {
    value: "hire_purchase",
    label: "Hire purchase",
  },
  {
    value: "unsecured_loan",
    label: "Unsecured loan (e.g. payday loan)",
  },
  {
    value: "insurance",
    label: "Tax/ National insurance / VAT",
  },
  {
    value: "tax_credits",
    label: "Tax credits",
  },
  {
    value: "child_maintenance",
    label: "Child maintenance",
  },
  {
    value: "parking_tickets",
    label: "Parking tickets",
  },
  {
    value: "none",
    label: "None of the above",
  },
];

export const bestDescribesList = [
  {
    value: "spend_more",
    label: "Spend more than you earn",
  },
  {
    value: "spend_earn",
    label: "Spend what you earn",
  },
  {
    value: "spend_less",
    label: "Spend less than you earn",
  },
];

// export const allFactorsList = [
//   {
//     value: "reduced_income",
//     label: "Reduced income",
//   },
//   {
//     value: "refused_benefits",
//     label: "Refused benefits",
//   },
//   {
//     value: "stopped_delayed",
//     label: "Benefits stopped or delayed",
//   },
//   {
//     value: "benefits_incorrectly",
//     label: "Benefits incorrectly paid",
//   },
//   {
//     value: "debt_arrears",
//     label: "Debt/arrears",
//   },
//   {
//     value: "unpaid_bills",
//     label: "Unpaid bills",
//   },
//   {
//     value: "pay_for_food",
//     label: "Can't pay for food",
//   },
//   {
//     value: "gas_electric",
//     label: "Can't pay for gas/electric",
//   },
//   {
//     value: "rent_deposit",
//     label: "Can't pay for rent/deposit",
//   },
//   {
//     value: "risk_of_eviction",
//     label: "Risk of eviction",
//   },
//   {
//     value: "disability",
//     label: "Disability",
//   },
//   {
//     value: "health_issue",
//     label: "Serious health issue",
//   },
//   {
//     value: "gambling",
//     label: "Gambling",
//   },
//   {
//     value: "controlling_money",
//     label: "Another person is controlling my money",
//   },
//   {
//     value: "taken_debt",
//     label: "I've taken our debt for someone else",
//   },
//   {
//     value: "bayliff_action",
//     label: "Bayliff action",
//   },
//   {
//     value: "court_summons",
//     label: "Court summons",
//   },
//   {
//     value: "none_above",
//     label: "None of the above",
//   },
// ];

export const generalTableData = [
  {
    id: 1,
    item: "Total all time sign ups",
    metric: "X",
    changeMonth: "WIP",
    change6Month: "WIP",
    key: "totalSignups",
  },
  {
    id: 2,
    item: "Total all time users",
    metric: "X",
    changeMonth: "WIP",
    change6Month: "WIP",
    key: "totalUsers",
  },
  {
    id: 3,
    item: "Total no. users last 30 days",
    metric: "X",
    changeMonth: "WIP",
    change6Month: "WIP",
    key: "totalNewUser30Days",
  },
  {
    id: 4,
    item: "Total no. active users last 30 days",
    metric: "X",
    changeMonth: "WIP",
    change6Month: "WIP",
    key: "totalActiveAccount30Days",
  },
  {
    id: 5,
    item: "No. App downloads/installs - Total",
    metric: "X",
    changeMonth: "WIP",
    change6Month: "WIP",
    key: "totalDownloads",
  },
  {
    id: 6,
    item: "Session length - avg time",
    metric: "XX:XX",
    changeMonth: "WIP",
    change6Month: "WIP",
    key: "averageSessionLength",
  },
];

export const locationsBreakdownTableData = [
  {
    id: 1,
    location: "North East",
    totalNo: "WIP",
    percentage: "WIP",
  },
  {
    id: 2,
    location: "North East",
    totalNo: "WIP",
    percentage: "WIP",
  },
  {
    id: 3,
    location: "Yorkshire & Humbershire",
    totalNo: "WIP",
    percentage: "WIP",
  },
  {
    id: 4,
    location: "East Midlands",
    totalNo: "WIP",
    percentage: "WIP",
  },
  {
    id: 5,
    location: "West Midlands",
    totalNo: "WIP",
    percentage: "WIP",
  },
  {
    id: 6,
    location: "East of England",
    totalNo: "WIP",
    percentage: "WIP",
  },
  {
    id: 7,
    location: "London",
    totalNo: "WIP",
    percentage: "WIP",
  },
  {
    id: 8,
    location: "South East",
    totalNo: "WIP",
    percentage: "WIP",
  },
  {
    id: 9,
    location: "South East",
    totalNo: "WIP",
    percentage: "WIP",
  },
  // Add more data as needed
];

export const customerBreakdownTableData = [
  // {
  //   id: 1,
  //   personas: "Debtors",
  //   totalNo: "WIP",
  //   percentage: "WIP",
  // },
  {
    id: 2,
    personas: "Strugglers - Spend more than they earn",
    totalNo: "WIP",
    percentage: "WIP",
  },
  {
    id: 3,
    personas: "Spenders - Spend what they earn",
    totalNo: "WIP",
    percentage: "WIP",
  },
  {
    id: 4,
    personas: "Builders - Spend less than they earn",
    totalNo: "WIP",
    percentage: "WIP",
  },
];

export const financialTableData = [
  {
    id: 1,
    item: "Total aggregated debt amount (beginning of month)",
    identifier: "total_debt_start_month",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  {
    id: 2,
    item: "Total aggregated debt amount (end of month)",
    identifier: "total_debt_end_month",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  {
    id: 3,
    item: " Total avg amount of debt per user (beginning of month)",
    identifier: "avg_debt_start_month",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  {
    id: 4,
    item: " Total avg amount of debt per user (end of month)",
    identifier: "avg_debt_end_month",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  // {
  //   id: 5,
  //   item: "Avg total non-partner debt (beginning of month)",
  //   identifier: "WIP",
  //   metric: "WIP",
  //   changeMonth: "WIP",
  //   change3Month: "WIP",
  //   change6Month: "WIP",
  // },
  // {
  //   id: 6,
  //   item: "Avg total non-partner debt (end of month)",
  //   identifier: "WIP",
  //   metric: "WIP",
  //   changeMonth: "WIP",
  //   change3Month: "WIP",
  //   change6Month: "WIP",
  // },
  // {
  //   id: 7,
  //   item: "% of customers with other debt (outside selected partner)",
  //   identifier: "WIP",
  //   metric: "WIP",
  //   changeMonth: "WIP",
  //   change3Month: "WIP",
  //   change6Month: "WIP",
  // },
  {
    id: 8,
    item: "Avg amount of debt per debt",
    identifier: "avg_debt_each_user",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  {
    id: 9,
    item: "Avg no. debts per user",
    identifier: "avg_no_debts",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },

  {
    id: 10,
    item: "Avg debt free time (months)",
    identifier: "avg_debt_free_time",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  {
    id: 12,
    item: "% customers who've reduced their debt amount",
    identifier: "amount_reduced_customer_percent",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  {
    id: 13,
    item: "Avg amount £ debt reduced by this month",
    identifier: "avg_debt_amount_reduced",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  {
    id: 14,
    item: "£ avoided in interest - total",
    identifier: "monthly_interest_avoided",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  {
    id: 15,
    item: "£ avoided in interest - avg individual",
    identifier: "monthly_interest_avoided_avg",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  {
    id: 16,
    item: "Avg no. monthly of bills per user last month",
    identifier: "avg_number_of_bills",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
  {
    id: 17,
    item: "Avg Total amount of bills per user last month",
    identifier: "avg_amount_of_bills",
    metric: "WIP",
    changeMonth: "WIP",
    change3Month: "WIP",
    change6Month: "WIP",
  },
];

export const collectionsReportingTableData = [
  {
    id: 1,
    item: "Total number of missed bills per month",
    identifier: "missed_bills",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 2,
    item: "Total number of bills paid on time",
    identifier: "bills_paid_on_time",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 3,
    item: "% of customers making overpayments on credit cards",
    identifier: "customer_overpayment_cards",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 4,
    item: "Total number of at risk payments",
    identifier: "avg_risk_payments",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 5,
    item: "Total number of at risk payments made successfully",
    identifier: "avg_risk_payments_paid_success",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 6,
    item: "% of customers only paying minimum on debt",
    identifier: "customer_paying_min_debt",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 7,
    item: "% of customers following SuperFi debt suggestions from CRC",
    identifier: "customer_follow_crc",
    metric: "WIP",
    changeMonth: "WIP",
  },

  {
    id: 9,
    item: "No. users who've missed a payment",
    identifier: "users_missed_payment",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 10,
    item: "No. users in arrears at start of month",
    identifier: "users_arrears_start_month",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 11,
    item: "No. users in arrears at end of month",
    identifier: "users_arrears_end_month",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 12,
    item: "Total number of clicks for 'I can't afford to pay'",
    identifier: "total_cap_clicks",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 13,
    item: "No. users who've clicked through to partner support",
    identifier: "total_partner_support_clicks",
    metric: "WIP",
    changeMonth: "WIP",
  },
];

export const consumerDutyTableData = [
  {
    id: 1,
    item: "% of vulnerable customers",
    metric: "WIP",
    identifier: "vulnerable_customers",
    changeMonth: "WIP",
  },
  {
    id: 2,
    item: "No. users eligible for benefits",
    identifier: "users_eligigble_for_benefits",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 3,
    item: "Avg amount £ of benefits eligible for (monthly)",
    identifier: "avg_amount_eligigble_for_benefits",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 4,
    item: "No. users eligible for housing support",
    identifier: "housing_support",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 5,
    item: "No. users eligible for employment support",
    identifier: "employment_support",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 6,
    item: "No. users signposted to StepChange",
    metric: "WIP",
    identifier: "step_change_signpost",
    changeMonth: "WIP",
  },
  {
    id: 7,
    item: "No. users who've clicked through to any partner support",
    identifier: "speak_to_your_provider",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 8,
    item: "% of users sleeping better",
    identifier: "users_sleeping_better",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 9,
    item: "% of users with reduced money worries",
    identifier: "users_reduced_money_worries",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 10,
    item: "% of users that felt they received good outcome based on their personal situation",
    identifier: "users_good_outcome_personal",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 11,
    item: "% of users that felt SuperFi was easy to use",
    identifier: "users_superfi_easy_use",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 12,
    item: "% of users that felt SuperFi app & comms were easy to understand",
    identifier: "users_superfi_easy_comms",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 13,
    item: "% of users that feel SuperFi has improved their mental wellbeing",
    identifier: "users_superfi_mental_wellbeing",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 14,
    item: "% of users feel that SuperFi has reduced the need to get support from their bill provider",
    identifier: "users_reduced_bill_provider_support",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 15,
    item: "% of users feel that SuperFi has improved ability to pay bills on time",
    identifier: "users_improved_pay_bills_time",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 16,
    item: "% of users feel SuperFi has improved financial wellbeing",
    identifier: "users_improved_fin_wellbeing",
    metric: "WIP",
    changeMonth: "WIP",
  },
];

export const paymentTableData = [
  {
    id: 1,
    item: "Credit card",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 2,
    item: "BNPL",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 3,
    item: "Mortgages",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 4,
    item: "Subscriptions",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 5,
    item: "Utility bills",
    metric: "WIP",
    changeMonth: "WIP",
  },
  {
    id: 6,
    item: "Council tax",
    metric: "WIP",
    changeMonth: "WIP",
  },
];

export const getSituation = (situation, situations = []) => {
  var situationArray = situation ? situation.split(",") : [];
  var value = "";

  if (situationArray.length > 1) {
    for (let someData of situationArray) {
      let findLabelIndex = situations.findIndex(
        (data) => data.value == someData.trim()
      );
      if (findLabelIndex >= 0) {
        value = value + situations[findLabelIndex].label + ", ";
      }
    }
    value = value.substring(0, value.length - 2);
  } else if (situationArray.length == 1) {
    for (let someData of situationArray) {
      let findLabelIndex = situations.findIndex(
        (data) => data.value == someData
      );
      if (findLabelIndex >= 0) {
        value = situations[findLabelIndex].label;
      }
    }
  } else value = "-";
  return value;
};
